import { css } from "@emotion/react"
import { Link } from "gatsby"
import React from "react"
import Byteslogo from "../../assets/images/superpod/byteslogo.svg"
import Podlogo from "../../assets/images/superpod/podlogo.svg"
import RecessionLogo from "../../assets/images/superpod/recessionLogo.svg"
import Roadto from "../../assets/images/superpod/road-to.svg"
import SVGIcon from "../common/SVGIcon"
const Animecard = props => {
  const { cardName, slug, subtext, title, image } = props.data
  return (
    <Link to={`/${slug}`}>
      <div
        className={`anime-card overflow-hidden prelative ${cardName}`}
        css={css`
          background-image: url(${image[0].url});
          &:after {
            background-image: url(${image[0].url});
          }
        `}
      >
        {cardName !== "podcast" ? (
          <div className="card-logo prelative">
            {(cardName === "million" || cardName === "limited") && (
              <img src={Podlogo} alt="logo" />
            )}
            <div className="title-img prelative">
              <img
                src={
                  cardName === "bytes"
                    ? Byteslogo
                    : cardName === "million"
                    ? Roadto
                    : RecessionLogo
                }
                alt="extralogo"
              />
            </div>
            <div className="epi-no prelative">
              <p>{title[0]}</p>
            </div>
          </div>
        ) : (
          <div className="card-overlay prelative">
            <img
              className="logo-img prelative"
              src={Podlogo}
              alt="superpodLogo"
            />
            <div className="card-title prelative">
              <SVGIcon name="superpod/msp-show" />
              <div className="epi-no prelative">
                <p>{title[0]}</p>
              </div>
            </div>
          </div>
        )}
        <div className="content-Bx d-flex position-absolute w-100">
          {cardName !== "bytes" && (
            <div className="season-title">{subtext[0]}</div>
          )}
          <button className="w-100">PLAY NOW</button>
        </div>
      </div>
    </Link>
  )
}

export default Animecard
