import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Col, Container, InputGroup, Row } from "react-bootstrap"
import QuoteSmall from "../../assets/images/superpod/quote-small.png"
import SuperpodLogo from "../../assets/images/superpod/superpod-logo.png"
import CTA from "../../components/common/CTA"
import FormBuilder from "../../components/common/FormBuilder/Form-Builder"
import Frame from "../../components/common/frame"
import Links from "../../components/common/links"
import Navigation from "../../components/navigation"
import Featuredcontainer from "../../components/superpod/OnemillionContainer"
import Animecard from "../../components/superpod/superpod_main_card"
import Slide from "../../components/utility/slide_content"
import "../../styles/superpod.scss"
import "../../styles/superpodnew.scss"

function SuperPod(props) {
  return (
    <>
      <StaticQuery
        query={graphql`
          query contentSuperPod1 {
            SuperOps {
              pages(where: { title: "SuperPodMain" }) {
                title
                newField {
                  ... on SuperOps_Card {
                    __typename
                    cardName
                    slug
                    title
                    subtext
                    image {
                      url
                    }
                  }
                }
                pageBlock {
                  id
                  content {
                    html
                    text
                  }
                }
                navigationBlock {
                  name
                  slug
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
              links(where: { section: "Superpod" }) {
                section
                icon {
                  url
                }
                slug
                linkName
              }
              episodes(where: { isFeatured: Yes }, orderBy: createdAt_DESC) {
                id
                season
                superpodPage
                audioDuration
                episodeNumber
                coverImage {
                  url(
                    transformation: { document: { output: { format: webp } } }
                  )
                }
                image {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 540 } }
                    }
                  )
                }
                title
                slug
              }
              pageComponents(where: { pageName: "limitedEdition" }) {
                ctaBoxes {
                  type
                  theme
                  customBackgroundColor
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  isLinkVisible
                  listenLinkText
                  links {
                    section
                    icon {
                      url
                    }
                    slug
                    linkName
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            {data.SuperOps.pages.map(page => {
              const { title, pageBlock, navigationBlock, seo, newField } = page
              const featuredEpisode = data.SuperOps.episodes[0]
              const slug =
                featuredEpisode.superpodPage === "podcast"
                  ? "season1"
                  : featuredEpisode.superpodPage === "bytes"
                  ? "superpod-bytes"
                  : featuredEpisode.superpodPage === "limitedEdition"
                  ? "limited-edition"
                  : "season2"
              return (
                <div className="superpodmain">
                  <div className="pod-main mtm120 bg-black bg-dark">
                    <Frame
                      seo={seo}
                      theme="dark"
                      bodyClassName="bg-black"
                      footerClassName="plr superpod-footer"
                    >
                      <header>
                        <Navigation links={navigationBlock} page={title} />
                      </header>
                      <section className="sp-banner hero-banner">
                        <Container className="Layout-container">
                          <center>
                            <div className="content prelative">
                              <Slide style={{ "--sal-delay": "0.2s" }}>
                                <img
                                  src={SuperpodLogo}
                                  className="superpod-logo"
                                  alt="logo"
                                />
                                <p className="banner-subtext">
                                  {parse(pageBlock[0].content.text)}
                                </p>
                              </Slide>

                              <Slide style={{ "--sal-delay": "0.2s" }}>
                                <div className="line" />
                                <Slide style={{ "--sal-delay": "0.2s" }}>
                                  <div className="social-links-banner flex-wrap d-flex justify-content-center">
                                    <Links
                                      className="icons"
                                      items={data.SuperOps.links[0]}
                                      iconOnly
                                      name
                                    />
                                  </div>
                                </Slide>
                              </Slide>
                              <Slide style={{ "--sal-delay": "0.2s" }}>
                                <InputGroup className="flex-column">
                                  <FormBuilder
                                    formClassName="hero-form"
                                    errorClassName="error-message"
                                    inputClassName="email"
                                    inputErrorClassName="error-active"
                                    buttonText="SUBSCRIBE"
                                    buttonClassName="primary white"
                                    type="subscribe"
                                    path="subscription"
                                  />
                                  <p className="subscribe-text">
                                    {parse(pageBlock[1].content.text)}
                                  </p>
                                </InputGroup>
                              </Slide>
                            </div>
                          </center>
                        </Container>
                      </section>
                      <section className="sp-cta-video prelative">
                        <Slide delay="200">
                          <Container className="prelative Layout-container ">
                            <div className="ct-container">
                              <Row>
                                <Col md={4}>
                                  <p className="f-highlight">featured</p>
                                  <p className="left-title">
                                    {parse(pageBlock[8].content.text)}
                                  </p>
                                </Col>
                                <Col
                                  md={8}
                                  className="prelative featured-cta-box"
                                >
                                  {featuredEpisode && (
                                    <Featuredcontainer
                                      data={featuredEpisode}
                                      slug={slug}
                                      isFeatured
                                    />
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Container>
                        </Slide>
                      </section>

                      <section className="mainpage-cards prelative">
                        <center>
                          <Container className="Layout-container">
                            <div className="cardslink d-flex">
                              {newField.map(card => {
                                return <Animecard data={card} />
                              })}
                            </div>
                          </Container>
                        </center>
                      </section>

                      <section className="sp-letter">
                        <Container className="Layout-container">
                          <div className="d-flex justify-content-center">
                            <div className="content prelative">
                              <Row>
                                <Col md={5} className="left">
                                  <h2>{parse(pageBlock[2].content.text)}</h2>
                                  <div className="line" />
                                  <div className="quote-conatiner text-ash-grey">
                                    <img
                                      src={QuoteSmall}
                                      className="left objcvr"
                                      alt="Quote"
                                      width="10"
                                      height="10"
                                    />
                                    <p className="quote">
                                      {pageBlock[3].content.text}
                                    </p>
                                  </div>
                                  <div className="letter-img d-dsk">
                                    {parse(pageBlock[4].content.html)}
                                  </div>
                                </Col>
                                <Col md={7} className="right">
                                  {parse(pageBlock[5].content.html)}
                                  <div className="letter-img d-mob">
                                    {parse(pageBlock[4].content.html)}
                                  </div>
                                  {parse(pageBlock[6].content.html)}
                                  <div className="right-about text-ash-grey">
                                    {parse(pageBlock[7].content.html)}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Container>
                      </section>

                      <section className="sp-links-cta">
                        <Slide delay="200">
                          <CTA
                            data={[data.SuperOps.pageComponents[0].ctaBoxes[0]]}
                            className="Layout-container"
                            lgRight={6}
                            lgLeft={4}
                          />
                        </Slide>
                      </section>
                    </Frame>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default SuperPod
